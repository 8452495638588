<template>
  <div class="grid-nogutter flex flex-wrap grid-height">
    <div class="col-12">
      <Card
        id="main-content-card"
        class="main-content-card"
        :class="[
          CardHelperClasses.NO_SHADOWS,
          fullViewport ? CardHelperClasses.FULL_VIEWPORT : '',
          noPaddingLeft ? 'no-padding-left-part' : '',
          noPaddingRight ? 'no-padding-right-part' : '',
          noPaddingBotton ? 'no-padding-botton-part' : '',
        ]"
      >
        <template #header v-if="$slots?.header">
          <slot name="header" />
        </template>

        <template #content>
          <template v-if="loading">
            <div class="grid">
              <div class="col-12">
                <Skeleton width="70%" class="mb-2" />
                <Skeleton />
              </div>
              <div class="col-12">
                <Skeleton width="70%" class="mb-2" />
                <Skeleton />
              </div>
              <div class="col-12">
                <Skeleton width="70%" class="mb-2" />
                <Skeleton />
              </div>
              <div class="col-12">
                <Skeleton width="70%" class="mb-2" />
                <Skeleton />
              </div>
            </div>
          </template>

          <template v-else>
            <slot name="content" />
          </template>
        </template>
      </Card>
    </div>
  </div>
</template>

<script setup lang="ts">
import { Card, CardHelperClasses } from "../../containers/cards";
import { Skeleton } from "../../misc";
import { useContentPageStore } from "@centric-os/stores";
import { storeToRefs } from "pinia";

defineProps({
  loading: {
    type: Boolean,
    default: false,
  },
  fullViewport: {
    type: Boolean,
    default: false,
  },
  noPaddingLeft: {
    type: Boolean,
    default: false,
  },
  noPaddingRight: {
    type: Boolean,
    default: false,
  },
  noPaddingBotton: {
    type: Boolean,
    default: false,
  },
});

const contentPageStore = useContentPageStore();
const { contentPageLayoutHeight } = storeToRefs(contentPageStore);
</script>

<style lang="scss" scoped>
// .grid-height {
//   height: v-bind("contentPageLayoutHeight");
// }

:deep(.p-card) {
  &.card-full-viewport {
    height: v-bind("contentPageLayoutHeight");
    max-height: v-bind("contentPageLayoutHeight");

    .p-card-body {
      max-height: v-bind("contentPageLayoutHeight");
      // overflow-y: auto;
      padding-left: 1.5rem;
      padding-right: 1.5rem;
    }
  }

  &.no-padding-left-part {
    .p-card-body {
      padding-left: 0;
    }
  }

  &.no-padding-right-part {
    .p-card-body {
      padding-right: 0;
    }
  }

  &.no-padding-botton-part {
    .p-card-body {
      padding-bottom: 0;
    }
  }
}
</style>
